export const menuItems = [
  {
    title: "Business",
    items: [
      { title: "Insurance", link: "/" },
      { title: "Web Hosting", link: "/home/web-hosting" },
      { title: "Education", link: "/home/education" },
      { title: "N.P. Charity", link: "/home/charity" },
      { title: "Real Estate", link: "/home/real-estate" },
    ],
  },
  {
    title: "Landing",
    items: [
      { title: "Sass Product", link: "/home/sass-product" },
      { title: "App Landing", link: "/home/app-landing" },
      { title: "Crypto", link: "/home/crypto" },
      { title: "Personal Portfolio", link: "/home/personal-portfolio" },
    ],
  },
  {
    title: "Agency",
    items: [
      { title: "Agency Modern", link: "/home/agency-modern" },
      { title: "SEO & Digital Agency", link: "/home/seo-agency" },
      { title: "Design Agency", link: "/home/design-agency" },
      { title: "Lead Generation", link: "/home/lead-generation" },
    ],
  },
];

export const pagesItems = [
  {
    title: "About Us",
    subItems: [
      { title: "About Us One", link: "/pages-menu/about-us-v1" },
      { title: "About Us Two", link: "/pages-menu/about-us-v2" },
    ],
  },
  {
    title: "Services",
    subItems: [
      { title: "Service One", link: "/pages-menu/service-v1" },
      { title: "Service Two", link: "/pages-menu/service-v2" },
      { title: "Service Three", link: "/pages-menu/service-v3" },
      { title: "Service Details", link: "/pages-menu/service-details" },
    ],
  },
  {
    title: "Our Team",
    subItems: [
      { title: "Team One", link: "/pages-menu/team-v1" },
      { title: "Team Two", link: "/pages-menu/team-v2" },
    ],
  },
  {
    title: "Other Pages",
    subItems: [
      { title: "FAQ", link: "/pages-menu/faq" },
      { title: "Signin", link: "/login" },
      { title: "Signup", link: "/signup" },
      { title: "404 Error", link: "/404" },
    ],
  },
];

export const portfolioItems = [
  { name: "Portfolio 01", link: "/portfolio/portfolio-v1" },
  { name: "Portfolio 02", link: "/portfolio/portfolio-v2" },
  { name: "Portfolio 03", link: "/portfolio/portfolio-v3" },
  { name: "Portfolio 04", link: "/portfolio/portfolio-v4" },
  { name: "Portfolio 05", link: "/portfolio/portfolio-v5" },
  { name: "Portfolio 06", link: "/portfolio/portfolio-v6" },
  { name: "Portfolio 07", link: "/portfolio/portfolio-v7" },
  { name: "Portfolio Details", link: "/portfolio/1" },
];

export const blogItems = [
  { link: "/blog/blog-v1", text: "List With sidebar" },
  { link: "/blog/blog-v2", text: "Grid With sidebar" },
  { link: "/blog/blog-v3", text: "Grid Layout" },
  { link: "/blog/1", text: "Blog Details" },
];

export const navItems = [
  {
    label: "Home",
    submenuItems: [
      { title: "Insurance", link: "/" },
      { title: "Web Hosting", link: "/home/web-hosting" },
      { title: "Education", link: "/home/education" },
      { title: "N.P. Charity", link: "/home/charity" },
      { title: "Real Estate", link: "/home/real-estate" },
      { title: "Sass Product", link: "/home/sass-product" },
      { title: "App Landing", link: "/home/app-landing" },
      { title: "Crypto", link: "/home/crypto" },
      { title: "Personal Portfolio", link: "/home/personal-portfolio" },
      { title: "Agency Modern", link: "/home/agency-modern" },
      { title: "SEO & Digital Agency", link: "/home/seo" },
      { title: "Design Agency", link: "/home/design-agency" },
      { title: "Lead Generation", link: "/home/lead-generation" },
    ],
  },
  { title: "About Us", link: "/pages-menu/about-us-v1" },
  { title: "Service", link: "/pages-menu/service-v1" },
  { title: "Portfolio", link: "/portfolio/portfolio-v1" },
  { title: "Grid With sidebar", link: "/blog/blog-v2" },
  { title: "Contact us", link: "/contact" },
];
