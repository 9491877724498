import { Link } from "react-router-dom";

const Intro = ({ setting }) => {
  return (
    <>
      <div className="title-style-eleven">
        <div className="sc-title">ABOUT US</div>
        {/* <h2 className="main-title tx-dark">
          Create <br /> visual strategy lorem.
        </h2> */}
      </div>
      <div className="d-flex mt-70 mb-40 lg-mt-40 lg-mb-10">
        <img
          src="/images/shape/shape_164.svg"
          alt=""
          className="lazy-img d-none d-lg-block"
        />
        <span>
          <span className="text-lg">
            Property & Beyond prides itself on excellent and innovate customer
            service. In addition to maintaining financial stability through
            constant growth, it will become the top rank service provider and
            leader in property management in Lebanon. Established in 2023 as a
            partnership between Lebanese partners, who have rich and extensive
            experience in management and customer care services, as well as
            experience in delivering top-to-the-line business relation with
            exceptional quality of service to the widest range of customers
          </span>
          {/* <div dangerouslySetInnerHTML={{ __html: setting?.about_text }} /> */}
          <span style={{ textAlign: "end" }}>
            ...
            <Link
              to="/about"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              read more
            </Link>
          </span>
        </span>
      </div>
    </>
  );
};

export default Intro;
