import axios from "axios";

const API_URL = "https://api.propertyandbeyond.net";
export const ImageUrl = "https://api.propertyandbeyond.net/public/uploads/";
const axiosApi = axios.create({
  baseURL: API_URL,
});

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}