import { Link } from "react-router-dom";
const Image = require("../../../assets/images/media/img_75.jpg");
import Image_1 from "../../../assets/images/media/img_75.jpg";
import Image_2 from "../../../assets/images/media/img_75.jpg";
import Slider from "react-slick";
import { ImageUrl } from "../../../http/api_helper";
import { useRef } from "react";
import { SliderRef } from "../../../utils/constant";
var settings = {
  infinite: true,
  autoplay: true,
  speed: 500,
  slidesToShow:2,
  slidesToScroll: 1,
  nextArrow: <i class="bi bi-chevron-right"></i>,
  prevArrow: <i class="bi bi-chevron-left"></i>,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <></>,
        prevArrow: <></>,
      },
    },
    {
      breakpoint: 900,
      settings: {
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <></>,
        prevArrow: <></>,
      },
    },
    {
      breakpoint: 480,
      settings: {
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <></>,
        prevArrow: <></>,
      },
    },
  ],
};
const Property = ({ services }) => {

  const properties = [
    {
      image: Image,
      title: "Condos",
      delay: "100",
    },
    {
      image: Image_1,
      title: "Property",
      delay: "200",
    },
    {
      image: Image_2,
      title: "Home Rent",
      delay: "300",
    },
  ];

  return (
    <>
      <Slider ref={SliderRef} {...settings}>
        {services?.map((item, index) => (
         
          
<div
          className="col-md-6 d-flex p-2"
          data-aos="fade-up"
          data-aos-delay={(index + 1) * 100}
          key={index}
        >
          <article
            className="blog-meta-six d-flex flex-column position-relative zn2 mt-40"
            style={{ backgroundImage: `url(${ImageUrl + item.image})` ,backgroundSize:'cover'}}
          >
            <div className="post-data position-relative d-flex flex-column h-100">
              <div className="blog-header">
                <div className="mb-20">
                  <span
                    
                    className="tag text-uppercase fw-500 tran3s mb-5"
                  >
                    {'flat & Villas'}
                  </span>
                </div>
                <div className='mb-80 tag'>
          
                  <h3 className="tran3s text-white"> {item.title}</h3>

                </div>
              </div>
              {/* /.blog-header */}
              <div className="blog-footer d-flex align-items-center justify-content-between mt-auto">
                <div className="blog-date tran3s fw-500 tx-dark" dangerouslySetInnerHTML={{__html:item.full_desc}}>
                  
                
                </div>
               
              </div>
              {/* /.blog-footer */}
            </div>
            {/* /.post-data */}
          </article>
          {/* /.blog-meta-six */}
        </div>
        ))}
      </Slider>
    </>
  );
};

export default Property;
