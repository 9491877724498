import React, { Fragment } from "react";
import Seo from "../../components/common/Seo";

function Preloader() {
  return (
    <Fragment>
      <Seo pageTitle={"Property and beyond"} />
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={require("../../assets/images/loading1.gif")} width={"7%"} />
      </div>
    </Fragment>
  );
}

export default Preloader;
