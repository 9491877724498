import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  slideShows: [],
  services: [],
  setting: {},
  typeRouting:'home'
};

export const landingPageReducer = createSlice({
  name: "landingPage",
  initialState,
  reducers: {
    setSlideShows: (state, action) => {
      state.slideShows = action.payload;
    },
    setServices: (state, action) => {
      state.services = action.payload;
    },
    setSetting: (state, action) => {
      state.setting = action.payload;
    },
    setTypeRouting: (state, action) => {
      state.typeRouting = action.payload;
    },
  },
});

export const { setSlideShows, setServices, setSetting ,setTypeRouting} =
  landingPageReducer.actions;
export default landingPageReducer.reducer;
