import Seo from "../../components/common/Seo";
import DefaulHeader from "../../components/header/DefaulHeader";
import DefaultFooter from "../../components/footer/DefaultFooter";
import Partners from "../../components/services/Partners";
import Faq from "../../components/services/Faq";
import Testimonial from "../../components/home-page/home-3/Testimonial";
import Block from "../../components/services/Block";
import Team3 from "../../components/team/Team3";
import { Link } from "react-router-dom";
import VideoBlock from "../../components/about/VideoBlock";
import Counter from "../../components/about/Counter";
import Header5 from "../../components/header/Header5";
import Footer from "../../components/home-page/home-5/footer";
import { useEffect } from "react";

const AboutUsV1 = () => {
  const features = [
    { text: "To conduct complex government transactions." },
    { text: "To maintain the property inside out. " },
    { text: "To keep your property clean and durable. " },
    { text: "To represent you in any needed committee meetings. " },
    { text: "To follow up on any tasks assigned by clients. " },
    { text: "To keep clients posted 24/7 on all agreed tasks. " },
  ];

  const starRating = Array(5)
    .fill()
    .map((_, index) => (
      <li key={index}>
        <i className="fa-solid fa-star" />
      </li>
    ));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Seo pageTitle="About Us" />

      {/* <!-- 
      =============================================
      Theme Default Menu
      ============================================== 	
      --> */}
      <Header5 />

      {/* 
			=============================================
				Feature Section Fifty One
			============================================== 
			*/}
      <div className="fancy-feature-fiftyOne position-relative mt-200">
        <div className="container">
          <div className="row">
            <div className="col-lg-7" data-aos="fade-right">
              <div className="title-style-five mb-65 md-mb-40">
                <div className="sc-title-two fst-italic position-relative">
                  More About us.
                </div>
                <h2 className="main-title fw-500 tx-dark">
                  Welcome to Property & Beyond company.
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 col-lg-9 ms-auto">
              <div className="ps-xxl-5" data-aos="fade-left">
                <p className="text-md tx-dark ">
                  Your trusted partner in property management in Lebanon.
                  Whether you are a homeowner, an investor, or a real estate
                  developer, we understand the importance of efficient and
                  effective property management. With our expertise and
                  dedication, we strive to simplify the complexities of property
                  ownership, maximizing the value of your investment while
                  providing peace of mind. At Property & Beyond, we believe that
                  every property deserves the highest level of care and
                  attention. Our team of experienced professionals is committed
                  to delivering tailored solutions to meet your specific needs
                  and goals. From residential complexes to commercial buildings,
                  we have the knowledge and resources to handle all aspects of
                  property management, ensuring seamless operations and
                  satisfied stakeholders. We pride ourselves on our commitment
                  to excellence, transparency, and responsiveness. With our
                  proactive approach, we anticipate challenges, identify
                  opportunities, and implement strategies that optimize your
                  property's performance. Whether it's tenant acquisition and
                  retention, maintenance and repairs, financial management, or
                  legal compliance, we have you covered. Discover the peace of
                  mind that comes with professional property management. Contact
                  us today and let Property & Beyond unlock the full potential
                  of your property investment. Together, we can navigate the
                  complexities of property ownership and create a thriving
                  environment for your tenants, residents, or customers.
                </p>
                <div className="row">
                  <Counter />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /.container */}

        <img
          src="images/shape/shape_171.svg"
          alt="shape"
          className="lazy-img shapes shape-one"
        />
        <img
          src="images/shape/shape_172.svg"
          alt="shape"
          className="lazy-img shapes shape-two"
        />
      </div>
      {/* /.fancy-feature-fiftyOne */}

      {/* <!-- 
			=============================================
				Feature Section Fifty Two
			============================================== 
			--> */}
      <VideoBlock />

      {/* <!-- /.fancy-feature-fiftyTwo --> */}

      {/* 
			=============================================
				Feature Section Thirty Seven
			============================================== 
			*/}

      <div className="fancy-feature-thirtySeven mt-225 lg-mt-120 mb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div
                className="block-style-four pe-xxl-5 me-xxl-4 md-mb-70"
                data-aos="fade-right"
              >
                <div className="title-style-six">
                  <div className="sc-title-two text-uppercase">About us</div>
                </div>
                {/* /.title-style-ten */}
                <p className="fs-20 pt-20 pb-30 lg-pt-10 lg-pb-20">
                  Property & Beyond prides itself on excellent and innovate
                  customer service. In addition to maintaining financial
                  stability through constant growth, it will become the top rank
                  service provider and leader in property management in Lebanon.
                  Established in 2023 as a partnership between Lebanese
                  partners, who have rich and extensive experience in management
                  and customer care services, as well as experience in
                  delivering top-to-the-line business relation with exceptional
                  quality of service to the widest range of customers.
                </p>
                <h2 className="main-title fw-500 tx-dark">Mission</h2>

                <ul className="style-none list-item">
                  {features.map((feature, index) => (
                    <li key={index}>{feature.text}</li>
                  ))}
                </ul>
              </div>
            </div>
            {/* End .col */}

            <div className="col-xxl-5 col-lg-6 ms-auto">
              <Block />
              <img
                src="images/shape/shape_172.svg"
                alt="shape"
                className="lazy-img shapes shape-one d-none d-xl-inline-block"
              />
            </div>

            {/* End .col */}
          </div>
        </div>

        {/* /.container */}
      </div>
      {/* /.fancy-feature-thirtySeven */}

      {/*
			=====================================================
				Team Section Two
			=====================================================
			*/}

      {/* /.team-section-two */}

      {/*
			=====================================================
				Feedback Section Eleven
			=====================================================
			*/}

      {/* /.partner-section-six */}

      {/*
			=====================================================
				Fancy Short Banner Sixteen
			=====================================================
			*/}

      {/* /.fancy-short-banner-sixteen */}

      {/* 
        =============================================
        Contact Section One
        ============================================== 
        */}
      <Footer />
    </>
  );
};

export default AboutUsV1;
