module.exports = [
  {
    id: 1,
    title: "Very Solid!!",
    rating: 5,
    text: "They not only understand what I say but read between the lines and also give me ideas of my own.",
    author: "Rashed Kabir",
    location: "Claifornia",
    image: "/images/media/img_54.jpg",
  },
  {
    id: 2,
    title: "Very Solid!!",
    rating: 5,
    text: "They not only understand what I say but read between the lines and also give me ideas of my own.",
    author: "Rashed Kabir",
    location: "Claifornia",
    image: "/images/media/img_55.jpg",
  },
  {
    id: 3,
    title: "Very Solid!!",
    rating: 5,
    text: "They not only understand what I say but read between the lines and also give me ideas of my own.",
    author: "Rashed Kabir",
    location: "Claifornia",
    image: "/images/media/img_54.jpg",
  },
  {
    id: 4,
    title: "Very Solid!!",
    rating: 5,
    text: "They not only understand what I say but read between the lines and also give me ideas of my own.",
    author: "Rashed Kabir",
    location: "Claifornia",
    image: "/images/media/img_55.jpg",
  },
];
