import { useSelector } from "react-redux";

const Social = () => {
  const setting = useSelector((state) => state.landingPage.setting);
  console.log({ setting });
  const socialIcons = [
    { id: 1, link: setting.fbLink, icon: "fab fa-facebook-f" },
    { id: 2, link: setting.twitterLink, icon: "fab fa-twitter" },
    { id: 3, link: setting.linkedInLink, icon: "fab fa-linkedin-in" },
    { id: 4, link: setting.ytLink, icon: "fab fa-youtube" },
    { id: 5, link: setting.tiktoklink, icon: "fab fa-tiktok" },
    { id: 6, link: setting.instaLink, icon: "fab fa-instagram" },
  ];

  return (
    <ul className="d-flex social-icon style-none">
      {socialIcons.map((socialIcon) =>
        socialIcon.link ? (
          <li key={socialIcon.id}>
            <a href={socialIcon.link} target="_blank" rel="noopener noreferrer">
              <i className={socialIcon.icon} />
            </a>
          </li>
        ) : (
          <></>
        )
      )}
    </ul>
  );
};

export default Social;
