import { useEffect } from "react";
import Routes from "./router/Routes";
import "./styles/index.scss";
import "aos/dist/aos.css";
import Aos from "aos";
import ScrollToTop from "./components/common/ScrollTop";
if (typeof window !== "undefined") {
  require("bootstrap/dist/js/bootstrap");
}
function App() {
  useEffect(() => {
    Aos.init({
      duration: 1200,
    });
  }, []);
  return (
    <div class="main-page-wrapper">
      <Routes />
      <ScrollToTop />
    </div>
  );
}

export default App;
