import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

const Counter = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });
  const setting = useSelector((state) => state.landingPage.setting);
  const data = [
    {
      count: setting.state_one_number,
      label: setting.state_one_text,
      unit: "+",
      colClass: "col-sm-6",
      delay: "100",
    },
    {
      count: setting.state_two_number,
      label: setting.state_two_text,
      unit: "+",
      colClass: "col-lg-4 col-sm-6",
      delay: "200",
    },
  ];
  return (
    <>
      {data.map(
        (item, index) =>
          item.count && (
            <div
              className={item.colClass}
              key={index}
              data-aos="fade-up"
              data-aos-delay={item.delay}
            >
              <div className="counter-block-three mt-40 md-mt-30" ref={ref}>
                <div className="main-count fw-500 tx-dark">
                  <CountUp
                    end={inView ? item.count : 0}
                    duration={2.5}
                    separator=","
                  >
                    {({ countUpRef }) => (
                      <span ref={countUpRef} className="counter">
                        0
                      </span>
                    )}
                  </CountUp>
                  {item.unit}
                </div>
                <p className="tx-dark fs-18 m0">{item.label}</p>
              </div>
              {/* /.counter-block-three */}
            </div>
          )
      )}
    </>
  );
};

export default Counter;
