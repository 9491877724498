import Seo from "../../components/common/Seo";
import DefaulHeader from "../../components/header/DefaulHeader";
import DefaultFooter from "../../components/footer/DefaultFooter";
import SocialShare from "../../components/portfolio/portfolio-details/SocialShare";
import ProjectDetails from "../../components/portfolio/portfolio-details/ProjectDetails";
import ProjectSlide from "../../components/portfolio/portfolio-details/ProjectSlide";
import CallToAction from "../../components/portfolio/CallToAction";
import PortfolioGallery from "../../components/portfolio/portfolio-details/PortfolioGallery";

import { useEffect, useState } from "react";
import portfolioData from "../../data/portfolio";
import PortfolioDetailsTitle from "../../components/portfolio/portfolio-details/PortfolioDetailsTitle";
import { useLocation } from "react-router-dom";
import Header5 from "../../components/header/Header5";
import Footer from "../../components/home-page/home-5/footer";

const DynamicPortfolioDetails = () => {
  const [portfolio, setPortfolio] = useState({});
  const id = 1;
  const Location = useLocation();

  useEffect(() => {
    if (!id) <h1>Loading...</h1>;
    else setPortfolio(portfolioData.find((item) => item.id == id));
    window.scrollTo(0, 0);
    return () => {};
  }, [id]);

  return (
    <>
      <Seo pageTitle="property and beyond" />
      {/* <!-- 
      =============================================
      Theme Default Menu
      ============================================== 	
      --> */}
      <Header5 />

      {/* 
        =============================================
        Feature Section Fifty One
        ============================================== 
        */}
      <PortfolioDetailsTitle portfolio={Location.state} />

      {/* 
			=============================================
				Portfolio Details Two
			============================================== 
			*/}
      <div className="portfolio-details-two pt-70 pb-50 lg-pb-10 md-pt-10">
        <div className="project-desctiption">
          <div className="container">
            <div className="row">
              <div className="col-lg-12" data-aos="fade-right">
                <ProjectSlide slideShows={[{ image: Location.state?.image }]} />
                {/* /#gallery-carousel */}
              </div>
              {/* End .col-lg-8 */}

              {/* <div className="col-lg-4" data-aos="fade-left">
                <div className="sidebar ms-xl-5">
                  <h3 className="mb-20">About</h3>
                  <p className="border-bottom pb-40 mb-35 lg-pb-20">
                    The Internet advertising famous today behaved lately.
                  </p>
                  <div className="row">
                    <ProjectDetails details={location} />
                  </div>
                  

                  <SocialShare />
                </div>
              </div> */}
              {/* End col-lg-4 */}
            </div>
            {/* End .row */}

            <div className="col-xl-12  mt-120 lg-mt-80">
              <div
                className="title-style-twelve mb-45 lg-mb-30 wow fadeInUp"
                data-aos="fade-up"
              >
                <div className="sc-title fst-italic position-relative">
                  Overview
                </div>
                <h2 className="main-title fw-500 tx-dark">
                  About {Location.state?.title}
                </h2>
              </div>
              {/* /.title-style-twelve */}
              <p data-aos="fade-up">{Location.state?.short_desc}</p>
              <div className="row">
                <div className="col-xl-12">
                  <div
                    className="title-style-twelve mb-45 pt-75 lg-pt-40 lg-mb-30 wow fadeInUp"
                    data-aos="fade-up"
                  >
                    <div className="sc-title fst-italic position-relative">
                      Find more
                    </div>
                  </div>
                  {/* /.title-style-twelve */}
                </div>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: Location.state?.full_desc }}
                data-aos="fade-up"
              />

              <div className="row">
                {Location.state?.media && Location.state?.media.length > 0 ? (
                  <PortfolioGallery media={Location.state?.media} />
                ) : null}
              </div>

              {/* /.title-style-twelve */}
            </div>
            {/* End col-xl-9 */}

            {/* /.project-pagination */}
          </div>
          {/* End .container */}
        </div>
        {/* /.project-desctiption */}
      </div>
      {/* /.project-details */}

      {/*
			=====================================================
				Fancy Short Banner Twelve
			=====================================================
			*/}
      {/* <CallToAction /> */}

      {/* 
        =============================================
        Contact Section One
        ============================================== 
        */}
      {/* <DefaultFooter /> */}
      <Footer />
    </>
  );
};

export default DynamicPortfolioDetails;
