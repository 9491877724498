const Block = () => {
  return (
    <>
      <div className="row align-items-end" data-aos="fade-up">
        <div className="col-md-5 col-sm-4">
          <img
            src="images/shape/shape_136.svg"
            alt="shape"
            className="lazy-img d-none d-sm-inline-block"
          />
        </div>
        <div className="col-md-7 col-sm-8 wow fadeInDown">
          <div className="block-wrapper block-one">
            <h3 style={{ color: "#FFAE10" }}>Who are we? </h3>
            <p>
              We are Lebanese partners who introduced this concept after
              suffering from the absence of such services while living overseas
              or far from our properties.
            </p>
          </div>
          {/* /.block-wrapper */}
        </div>
      </div>
      {/* End .row */}

      <div className="row gx-xxl-5" data-aos="fade-down">
        <div className="col-sm-7 wow fadeInDown">
          <div className="block-wrapper block-two position-relative mt-50 sm-mt-30">
            <h3 style={{ color: "#9650EF" }}>
              <span>What do we do?</span>
            </h3>
            <p>
              We provide a wide array of services to attend to and care for
              properties on behalf of their owners. We have trustful and
              reliable partnerships with specialized businesses to service
              properties and represent owners in any needed situation.
            </p>
            <img
              src="images/shape/shape_138.svg"
              alt="sahpe"
              className="lazy-img shapes shape-one"
            />
          </div>
          {/* /.block-wrapper */}
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Block;
