import { useEffect, useState } from "react";
import MainMenu from "./MainMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Header5 = () => {
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState(false);
  const location = useLocation();
  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <header
      className={`theme-main-menu sticky-menu theme-menu-ten ${
        navbar ? "fixed" : ""
      }`}
    >
      <div className="inner-content position-relative">
        <div className="d-flex align-items-center justify-content-between">
          <img
            onClick={() =>
              location.pathname != "/" ? navigate("/") : window.scrollTo(0, 0)
            }
            src={require("../../assets/images/Logo_2.png")}
            className="propertyandbeyondlogo"
          />

          <div className="d-flex align-items-center justify-content-between">
            <MainMenu />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header5;
