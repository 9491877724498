const Map = ({ setting }) => {
  return (
    // <div className="p-2 mt-0 mb-0 ">
    //   <div className="box-layout">
    //     <div className="mapouter">
    //       <div className="gmap_canvas">
    // <div className="container">
    <div
      dangerouslySetInnerHTML={{
        __html: setting.contact_map,
      }}
    />
    // </div>
    //   </div>
    // </div>
    // </div>
  );
};

export default Map;
