import Seo from "../../components/common/Seo";
import Header5 from "../../components/header/Header5";
import FilterProperty from "../../components/home-page/home-5/ProperyFilter";
import Property from "../../components/home-page/home-5/Property";
import Quote from "../../components/home-page/home-5/Quote";
import Hero from "../../components/home-page/home-5/hero";
import About from "../../components/home-page/home-5/about";
import FancySection from "../../components/home-page/home-5/FancySection";
import Testimonial from "../../components/home-page/home-5/Testimonial";
import Blog from "../../components/home-page/home-5/Blog";
import Faq from "../../components/home-page/home-5/Faq";
import CallToActions from "../../components/home-page/home-5/CallToActions";
import Footer from "../../components/home-page/home-5/footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Image_1 from "../../assets/images/assets/sticker_01.png";
import { useEffect, useRef } from "react";
import Map from "../../components/contact/Map";
import ContactForm from "../../components/home-page/home-1/ContactForm";
import ProjectSlide from "../../components/portfolio/portfolio-details/ProjectSlide";
import { useDispatch, useSelector } from "react-redux";
import {
  getServices,
  getSettings,
  getSlideShows,
} from "../../redux/actions/landingPage";
import Preloader from "./preloader";
import {
  MapRef,
  aboutRef,
  contactRef,
  servicesRef,
  SliderRef,
} from "../../utils/constant";
const RealEstate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const slideshows = useSelector((state) => state.landingPage.slideShows);
  const services = useSelector((state) => state.landingPage.services);
  const setting = useSelector((state) => state.landingPage.setting);
  useEffect(() => {
    dispatch(getSlideShows());
    dispatch(getServices());
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    console.log({ location });
    if (location.state) {
      location.state == "home"
        ? window.scrollTo(0, 0)
        : location.state == "services"
        ? servicesRef.current.scrollIntoView()
        : location.state == "about"
        ? aboutRef.current.scrollIntoView()
        : location.state == "contact"
        ? contactRef.current.scrollIntoView()
        : MapRef.current.scrollIntoView();
    }
    navigate(location.pathname, {});
    return () => {};
  }, [location.state]);

  return !slideshows.length ? (
    <Preloader />
  ) : (
    <>
      <Seo pageTitle="Property and Beyond" />

      {/* <!--  =============================================
        Theme Header3 Menu
        ============================================== 	--> */}
      <Header5 />

      {/* 
        =============================================
        Theme Hero Banner
        ============================================== 
        */}
      <ProjectSlide slideShows={slideshows} />
      {/* <img
        style={{ position: "absolute", zIndex: 1 }}
        src="images/assets/sticker_01.png"
        alt="sticker"
        className="lazy-img shapes shape-one"
      />
      <div
        className="shapes shape-two"
        style={{ position: "absolute", zIndex: 1 }}
      />
      <img
        style={{ position: "absolute", zIndex: 1 }}
        src="images/shape/shape_162.svg"
        alt="sticker"
        className="lazy-img shapes shape-three"
      />
      <img
        style={{ position: "absolute", zIndex: 1 }}
        src="images/assets/sticker_02.png"
        alt="sticker"
        className="lazy-img shapes shape-four"
      />
      <div
        style={{ position: "absolute", zIndex: 1 }}
        className="shapes shape-five"
      />
      <div
        style={{ position: "absolute", zIndex: 1 }}
        className="shapes shape-six"
      /> */}
      {/* 
			=============================================
			Feature Section Forty Six
			============================================== 
			*/}
      <div
        ref={servicesRef}
        className="blog-section-six"
      >
        <div className="container">
          <div className="line-bg-wrapper position-relative pt-100 pb-90 lg-pt-120 md-pb-130">
            <div className="row align-items-center">
              <div className="col-lg-6" data-aos="fade-right">
                <div className="title-style-eleven md-mb-20">
                  <h2 className="main-title tx-dark">
                    Welcome to Property & Beyond company.
                  </h2>
                </div>
              </div>
              <div className="col-lg-5 ms-auto " data-aos="fade-left">
                <p className="text-lg m0">
                  Your trusted partner in property management in Lebanon.
                  Whether you are a homeowner, an investor, or a real estate
                  developer, we understand the importance of efficient and
                  effective property management. With our expertise and
                  dedication, we strive to simplify the complexities of property
                  ownership, maximizing the value of your investment while
                  providing peace of mind.
                </p>
              </div>
            </div>
            {/* End .row */}
           
            <div className="row carousel gx-xxl-5 lg-pt-40 md-pt-20">
          
              <Property services={services} />
            
      <button
        className="carousel-control-prev"
        type="button"
style={{marginLeft:-120}}
onClick={()=>SliderRef.current.slickPrev()}
      >
        <i className="bi bi-chevron-left text-white text-lg" style={{borderRadius:30,backgroundColor:'#007bff', paddingRight:10,paddingLeft:7,paddingTop:2}}></i>

      </button>
      {/* End prev */}

      <button
        className="carousel-control-next"
        type="button"
style={{marginRight:-120}}
onClick={()=>SliderRef.current.slickNext()}
      >
        <i className="bi bi-chevron-right text-white text-lg" style={{borderRadius:30,backgroundColor:'#007bff', paddingRight:7,paddingLeft:10,paddingTop:2}}></i>

      </button>
            </div>
            </div>
            </div>
          </div>
     

      <div
        ref={aboutRef}
        className="fancy-feature-fortyEight position-relative"
      >
        <div className="container">
          <div className="line-bg-wrapper position-relative pt-100 lg-pt-120 lg-pb-110">
            <About setting={setting} />
          </div>
          {/* /.line-bg-wrapper */}
        </div>
        {/* /.container */}
      </div>

      <div
        className="fancy-short-banner-thirteen pt-180 mt-130 lg-mt-100 lg-pt-80 lg-pb-80 "
        data-aos="fade-up"
        ref={contactRef}
      >
        <div className="container">
          <div className="bg-wrapper zn2 bg-white position-relative">
            <div className="row">
              <div className="col-xl-11 m-auto">
                <div className="row align-items-center">
                  <div className="col-lg-6 ms-auto order-lg-last">
                    <div className="text-wrapper">
                      <svg
                        width="36"
                        height="35"
                        viewBox="0 0 36 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M35.9998 21.5337V26.0224C36.014 27.5294 34.8691 28.8116 33.3274 29.015C33.0922 32.3781 30.2115 34.992 26.7316 35H19.0081C17.8991 35 17 34.1291 17 33.0549V30.5112C17 29.437 17.8991 28.5661 19.0081 28.5661C20.1172 28.5661 21.0162 29.437 21.0162 30.5112V31.1097H26.7316C28.016 31.1036 29.1073 30.1984 29.3112 28.9701C27.8065 28.7264 26.7107 27.4552 26.7316 25.9776V21.4888C26.7316 19.0097 28.8063 17 31.3657 17C32.6028 16.9999 33.7886 17.479 34.659 18.3306C35.5294 19.1821 36.0121 20.3354 35.9998 21.5337ZM18.159 3.90067C23.2256 3.97097 27.6855 7.30724 29.2495 12.1971C29.4661 12.8591 30.0143 13.3535 30.6877 13.4941C31.361 13.6348 32.0572 13.4003 32.5139 12.879C32.9706 12.3577 33.1186 11.6288 32.902 10.9669C30.8295 4.45123 24.8629 0.026081 18.1146 0C9.4353 0.115295 2.47774 7.32161 2.54357 16.1278V16.9979C1.07366 17.2087 -0.0144647 18.4924 0.000145387 19.9984V24.4992C0.000145387 26.9849 1.9863 29 4.43634 29C6.88639 29 8.87254 26.9849 8.87254 24.4992V19.9984C8.8914 18.512 7.83464 17.2357 6.38827 16.9979V16.2028C6.34086 12.9877 7.55443 9.88524 9.76194 7.57807C11.9695 5.27089 14.99 3.94807 18.159 3.90067Z"
                          fill="#30c1f0"
                        />
                      </svg>

                      <div className="title-style-one">
                        <h2 className="main-title fw-500 tx-dark m0">
                          Let’s talk with expereince advisors.
                        </h2>
                      </div>

                      <div className="fw-500 fs-18 ur-text mb-2 mt-30"></div>
                      <div className="call-btn">
                        <a
                          href={"tel:" + setting.contact_phone_1}
                          className="tx-dark tran3s text-md"
                        >
                          Call us: {setting.contact_phone_1}
                        </a>
                      </div>
                      <div className="call-btn">
                        <a
                          href={"mailto:" + setting.contact_email_1}
                          className="tx-dark tran3s text-md"
                        >
                          Email us: {setting.contact_email_1}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-6 order-lg-first">
                    <div className="form-style-two md-mb-40">
                      <ContactForm />
                    </div>

                    {/* /.form-style-two */}
                  </div>
                </div>
              </div>
            </div>

            {/* End .row */}

            <div className="shapes shape-text fw-500 fs-20 tx-dark text-center">
              Fill the <br />
              form
            </div>
            <img
              src="images/shape/shape_90.svg"
              alt="shape"
              className="lazy-img shapes shape-one"
            />
            <img
              src="images/shape/shape_91.svg"
              alt="shape"
              className="lazy-img shapes shape-two"
            />
          </div>
          {/* /.bg-wrapper */}
        </div>
      </div>
      <Map setting={setting} />

      {/* <!--
			=====================================================
				Footer
			=====================================================
			--> */}
      <Footer />
    </>
  );
};

export default RealEstate;
