import FilterBox from "./FilterBox";
import ReactLogo from "../../../../assets/images/shape/shape_161.svg";
import ReactLogo_1 from "../../../../assets/images/shape/shape_160.svg";
import ReactLogo_2 from "../../../../assets/images/shape/shape_159.svg";
import { ImageUrl } from "../../../../http/api_helper";
const Hero = ({ image }) => {
  return (
    <div
      className="zn2 pt-300 pb-300 lg-pb-150 lg-pt-150 md-pb-80 vh-100"
      style={{
        backgroundImage: `url(${ImageUrl + image.image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "cover",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-10 m-auto">
            <div className="text-wrapper" data-aos="fade-up">
              <h1 className="hero-heading fw-500 tx-dark text-center">
                {image.title}
                <span className="position-relative">
                  <img
                    src={ReactLogo}
                    alt="shape"
                    className="lazy-img shapes text-shape"
                  />
                </span>
              </h1>
              {/* <p className="text-lg tx-dark mb-80 pt-35 text-center lg-pt-20 lg-mb-50">
                {image.disk}
              </p> */}
            </div>
          </div>
        </div>
        {/* /.row */}

        {/* /.search-form-bg */}
      </div>
      {/* /.container */}

      <img
        src="images/shape/shape_160.svg"
        alt="shape"
        className="lazy-img shapes shape-two"
        style={{ position: "absolute", zIndex: 1, bottom: 0 }}
      />
      <img
        src="images/shape/shape_161.svg"
        alt="shape"
        className="lazy-img shapes text-shape "
        style={{ position: "absolute", zIndex: 1, top: "40vh", right: "20vh" }}
      />
      <img
        src={ReactLogo_2}
        alt="shape"
        className="lazy-img shapes shape-one d-none d-sm-block"
        style={{ position: "absolute", zIndex: 1, top: "45vh", left: "10vh" }}
      />
    </div>
  );
};

export default Hero;
