import { Link } from "react-router-dom";
import Address from "./Address";
import FooterMenu from "./FooterMenu";
import Social from "./Social";
import Logo from "../../../../assets/images/Logo_3.png";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSettings } from "../../../../redux/actions/landingPage";
const index = () => {
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSettings());
  }, []);
  return (
    <div className="footer-style-twelve theme-basic-footer position-relative zn2">
      <div className="container">
        <div className="line-bg-wrapper position-relative pt-130 pb-60 lg-pt-100 lg-pb-40">
          <div className="row justify-content-between">
            <div className="col-lg-4 footer-intro mb-40">
              <div className="logo pb-30">
                <Link href="/">
                  <img src={Logo} alt="brand" width={250} />
                </Link>
              </div>
              <Social />
            </div>
            {/* End .col-lg-3  */}
            <FooterMenu />

            <Address />
          </div>
          {/* End .row */}
          <p className="copyright text-center pt-30 m0"></p>
        </div>
        {/* /.inner-wrapper */}
      </div>
      {/* End .container */}
      <img
        src="images/shape/shape_170.svg"
        alt="shape"
        className="lazy-img shapes shape-one"
      />
    </div>
  );
};

export default index;
