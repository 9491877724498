import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import RealEstate from "../pages/home/real-estate";
import Contact from "../pages/contact";
import Pricing from "../pages/404";
import DynamicPortfolioDetails from "../pages/portfolio/[id]";
import AboutUsV1 from "../pages/pages-menu/about-us-v1";

function Routes() {
  return (
    <Switch>
      <Route path="/" element={<RealEstate />} />
      <Route path="/service" element={<DynamicPortfolioDetails />} />
      <Route path="/about" element={<AboutUsV1 />} />
      <Route path="/*" element={<Pricing />} />
    </Switch>
  );
}

export default Routes;
