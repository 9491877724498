import { useDispatch, useSelector } from "react-redux";
import {
  MapRef,
  aboutRef,
  contactRef,
  servicesRef,
} from "../../../../utils/constant";
import { Link, useNavigate } from "react-router-dom";
import { setTypeRouting } from "../../../../redux/reducers/landingPage";

const FooterMenu = () => {
  const setting = useSelector((state) => state.landingPage.setting);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const footerData = [
    {
      title: "",
      links: [
        { title: "Home", href: "", hash: "home" },
        { title: "Services", href: servicesRef, hash: "services" },
        { title: "About us", href: aboutRef, hash: "about" },
        { title: "Contact us", href: contactRef, hash: "contact" },
      ],
      colClass: "col-lg-2 col-sm-4 mb-30", // Add col class for this section
    },
    {
      title: "Services",
      links: [
        {
          title: setting.contact_phone_1,
          href: "/service-details",
          type: "phone",
        },
        {
          title: setting.contact_phone_2,
          href: "/service-details",
          type: "phone",
        },
        {
          title: setting.contact_email_1,
          href: "/service-details",
          type: "email",
        },
        {
          title: setting.contact_email_2,
          href: "/service-details",
          type: "email",
        },
        {
          title: setting.contact_address,
          href: "/service-details",
          type: "address",
        },
      ],
      colClass: "col-lg-2 col-sm-4 mb-30", // Add col class for this section
    },
  ];

  return (
    <>
      {/* <div style={{ display: "flex", rowGap: 30, flexDirection: "column" }}> */}
      <div className={"col-lg-2 col-sm-4 mb-30"}>
        <h5
          className="footer-title text-white fw-500"
          style={{ color: "black" }}
        >
          Quick links
        </h5>
        <ul className="footer-nav-link style-none">
          {footerData[0].links.map((link, linkIndex) => (
            <li key={linkIndex}>
              <Link
                onClick={() =>
                  location.pathname == "/service" ||
                  location.pathname == "/about"
                    ? navigate("/", { state: link.hash })
                    : linkIndex == 0
                    ? (window.scrollTo(0, 0),dispatch(setTypeRouting(link.hash)))
                    : (link.href.current.scrollIntoView(),dispatch(setTypeRouting(link.hash)))
                }
              >
                {link.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className={"col-lg-2 col-sm-4 mb-30"}>
        <h5 className="footer-title text-white fw-500">Contact info</h5>
        <ul className="style-none">
          {footerData[1].links.map((link, linkIndex) => (
            <li
              className={
                link.type == "address" ? " text-white" : "footer-nav-link"
              }
              key={linkIndex}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 10,
                opacity: link.type == "address" ? "0.7" : "1",
                marginTop: link.type == "address" ? 5 : 0,
              }}
            >
              {link.type == "address" ? (
                <i
                  style={{
                    opacity: link.type == "address" ? "0.7" : "1",
                  }}
                  class="fa-solid fa-location fa-lg"
                ></i>
              ) : linkIndex == 0 ? (
                <i class="fa-solid fa-phone fa-lg"></i>
              ) : linkIndex == 2 ? (
                <i class="fa-solid fa-envelope fa-lg"></i>
              ) : (
                <i
                  class="fa-solid fa-envelope fa-lg"
                  style={{ color: "black" }}
                ></i>
              )}
              <a
                href={
                  link.type == "phone"
                    ? `tel:${link.title}`
                    : link.type == "email" && `mailto:${link.title}`
                }
              >
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      {/* </div> */}
    </>
  );
};

export default FooterMenu;
