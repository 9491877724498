import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  menuItems,
  pagesItems,
  portfolioItems,
  blogItems,
} from "../../data/menu";
import {
  isActiveLink,
  isActiveParent,
  isActiveParentChaild,
} from "../../utils/linkActiveChecker";
import {
  MapRef,
  aboutRef,
  contactRef,
  servicesRef,
} from "../../utils/constant";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTypeRouting } from "../../redux/reducers/landingPage";

const MainMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
const state = useSelector(state=>state.landingPage.typeRouting)

  return (
    <nav className="navbar navbar-expand-lg order-lg-2">
      <button
        className="navbar-toggler d-block d-lg-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span />
      </button>

      {/* End mobile collapse menu */}

      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="d-block d-lg-none">
            <div className="logo">
              <Link href="/" className="d-block" data-bs-toggle="collapse">
                <img
                  src={require("../../assets/images/Logo_2.png")}
                  style={{ height: "10%", width: "80%" }}
                />
              </Link>
            </div>
          </li>
          {/* End li */}

          <li className="nav-item">
            <a
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              className="nav-link"
              style={state=='home' ? {color:'#30c1f0'}:{}}
              onClick={() =>
                location.pathname == "/service" || location.pathname == "/about"
                  ? navigate("/", { state: "home" })
                  : (window.scrollTo(0, 0),dispatch(setTypeRouting('home')))
              }
              role="button"
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              className="nav-link"
              style={state=='services' ? {color:'#30c1f0'}:{}}

              onClick={() =>
                location.pathname == "/service" || location.pathname == "/about"
                  ? navigate("/", { state: "services" })
                  : (servicesRef.current.scrollIntoView(),dispatch(setTypeRouting('services')))
              }
              role="button"
            >
              Services
            </a>
          </li>
          <li className="nav-item">
            <a
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              className="nav-link"
              style={state=='about' ? {color:'#30c1f0'}:{}}

              onClick={() =>
                location.pathname == "/service" || location.pathname == "/about"
                  ? navigate("/", { state: "about" })
                  : (aboutRef.current.scrollIntoView(),dispatch(setTypeRouting('about')))
              }
              role="button"
            >
              About us
            </a>
          </li>
          <li className="nav-item">
            <a
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              className="nav-link"
              style={state=='contact' ? {color:'#30c1f0'}:{}}

              onClick={() =>
                location.pathname == "/service" || location.pathname == "/about"
                  ? navigate("/", { state: "contact" })
                  : (contactRef.current.scrollIntoView({
                      block: "center",
                      inline: "start",
                    }),dispatch(setTypeRouting('contact')))
              }
              role="button"
            >
              Contact us
            </a>
          </li>
        </ul>
        {/* End ul */}

        {/* Mobile Content */}
        {/* <div className="mobile-content d-block d-lg-none">
          <div className="d-flex flex-column align-items-center justify-content-center mt-70">
            <Link href="/contact" className="btn-twentyOne fw-500 tran3s">
              Contact us
            </Link>
          </div>
        </div> */}
        {/* /.mobile-content */}
      </div>
    </nav>
  );
};

export default MainMenu;
