import Hero from "../../home-page/home-5/hero";

const Img = require("../../../assets/images/Logo_1.jpg");
const Img1 = require("../../../assets/images/Logo_2.png");
const ProjectSlide = ({ slideShows }) => {
  const images = [Img, Img1];

  return (
    <div
      id="gallery-carousel"
      className="carousel slide md-mb-40"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">
        {slideShows?.map((image, index) => (
          <div
            className={`carousel-item ${index === 0 ? "active" : ""}`}
            key={index}
          >
            <Hero image={image} />
          </div>
        ))}
      </div>
      {/* End carousel-inner */}

      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#gallery-carousel"
        data-bs-slide="prev"
      >
        <i className="bi bi-chevron-left"></i>
        <span className="visually-hidden">Previous</span>
      </button>
      {/* End prev */}

      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#gallery-carousel"
        data-bs-slide="next"
      >
        <i className="bi bi-chevron-right"></i>
        <span className="visually-hidden">Next</span>
      </button>

      {/* End next */}
    </div>
  );
};

export default ProjectSlide;
