import { get } from "../../http/api_helper";
import {
  setServices,
  setSetting,
  setSlideShows,
} from "../reducers/landingPage";

export const getSlideShows = () => async (dispatch, getState) => {
  try {
    const response = await get("/slideshow");
    dispatch(setSlideShows(response.data));
  } catch (e) {
    console.log(e);
  }
};

export const getServices = () => async (dispatch, getState) => {
  try {
    const response = await get("/service");
    dispatch(setServices(response.data));
  } catch (e) {
    console.log(e);
  }
};
export const getSettings = () => async (dispatch, getState) => {
  try {
    const response = await get("/setting");
    dispatch(setSetting(response.data[0]));
  } catch (e) {
    console.log(e);
  }
};
