module.exports = [
  {
    id: 1,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_10.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 2,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_11.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 3,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_12.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 4,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_13.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 5,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_14.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 6,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_15.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 7,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_16.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 8,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_17.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 9,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_18.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 10,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_19.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 11,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_20.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 12,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_21.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 13,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_22.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 14,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_23.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 15,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_24.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 16,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_25.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 17,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_26.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 18,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_27.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 19,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_28.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 20,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_29.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 21,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_30.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 22,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_31.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 23,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_32.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 24,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_33.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 25,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_34.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 26,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_35.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 27,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_36.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 28,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_37.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 29,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_38.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 30,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_39.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 31,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_39.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 32,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_40.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 33,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_41.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 34,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_42.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 35,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_44.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 36,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_45.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 37,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_46.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 38,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_47.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 39,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_48.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 40,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_49.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 41,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_50.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 42,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_51.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 43,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_52.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 44,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_53.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 45,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_54.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 46,
    category: "application dev",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_55.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 47,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_56.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 48,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_57.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 49,
    category: "design dev",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_58.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 50,
    category: "design marketing",
    name: "Mobile Application",
    tag: "Appliction",
    image: "/images/gallery/img_59.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 51,
    category: "application design marketing",
    name: "Minimal Iphone",
    tag: "Design",
    image: "/images/gallery/img_60.jpg",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 52,
    category: "branding illustration",
    image: "/images/gallery/img_01.jpg",
    name: "Product Analysis",
    tag: "Branding",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 53,
    category: "design logo",
    image: "/images/gallery/img_02.jpg",
    name: "UI, UX Design",
    tag: "Design",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 54,
    category: "branding design illustration",
    image: "/images/gallery/img_03.jpg",
    name: "Content Marketing",
    tag: "Illustration",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },
  {
    id: 55,
    category: "design logo",
    image: "/images/gallery/img_04.jpg",
    name: "Market System",
    tag: "Logo",
    projectInfo: {
      date: "23 July, 2020",
      clientName: "Mariona Adisson, USA",
      projectType: "UI/UX, Web Design",
    },
  },

  {
    id: 56,
    imgSrc: "/images/gallery/img_05.jpg",
    videoUrl: "AMg8Xws-8kA",
    iconClass: "bi bi-play-fill",
    overlayClass: "video-icon overlay-icon",
    captionTags: "UI/UX",
    captionTitle: "Anaylsis Application.",
  },
  {
    id: 57,
    imgSrc: "/images/gallery/img_06.jpg",
    zoomImgSrc: "/images/gallery/img_08.jpg",
    iconClass: "bi bi-plus",
    overlayClass: "tran3s overlay-icon zoom-icon",
    captionTags: "App",
    captionTitle: "The Bomb Squad",
  },
  {
    id: 58,
    imgSrc: "/images/gallery/img_07.jpg",
    videoUrl: "RBoUxI7eIW8",
    iconClass: "bi bi-play-fill",
    overlayClass: "video-icon overlay-icon",
    captionTags: "Mobile",
    captionTitle: "Weekend Warriors",
  },
  {
    id: 59,
    imgSrc: "/images/gallery/img_08.jpg",
    zoomImgSrc: "/images/gallery/img_08.jpg",
    iconClass: "bi bi-plus",
    overlayClass: "tran3s overlay-icon zoom-icon",
    captionTags: "Branding",
    captionTitle: "Associations Now",
  },
  {
    id: 60,
    imgSrc: "/images/gallery/img_09.jpg",
    videoUrl: "KVVzjRPv3Oo",
    iconClass: "bi bi-play-fill",
    overlayClass: "video-icon overlay-icon",
    captionTags: "Code",
    captionTitle: "The code Honors",
  },
];
